import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrivacyPolicyContent from "../components/PrivacyPolicyPage/PrivacyPolicyContent"

const PrivacyPolicy = (props) => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        description="We evaluate the state of your car’s hybrid battery and offer solutions to regenerate or replace it. Get in touch to schedule an appointment."
        keywords="Hybrid battery, hybrid car, hybrid batteries, hybrid vehicles, hybrid battery repair, i need diagnostic for my hybrid battery, check my battery, fix my battery, battery reconditioning, hybrid battery repair, hybrid battery kitchener, car service kitchener"
      />
      <PrivacyPolicyContent />
    </Layout>
  )
}

export default PrivacyPolicy